





























import { defineComponent } from '@nuxtjs/composition-api';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';

export default defineComponent({
  name: 'RelatedBanner',
  components: {
    Button,
    ImgView,
  },
  props: {
    bannerHeader: {
      type: String,
      required: true,
    },
    bannerDescription: {
      type: String,
      required: true,
    },
    bannerButtonText: {
      type: String,
      required: true,
    },
    bannerButtonLink: {
      type: String,
      required: true,
    },
    bannerImage: {
      type: String,
      required: true,
    },
  },
});
