










































































import { defineComponent, onMounted, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import type { ProductCardSize } from '~/constants/productCardSize';
import { PRODUCT_CARD_SIZE } from '~/constants/productCardSize';
import { useRouteBestPriceGuarantee, useProductDeliveryDate } from '~/composables';
import RelatedProduct from '~/components/organisms/Product/RelatedProducts/RelatedProduct.vue';
import RelatedBanner from '~/components/organisms/Product/RelatedProducts/RelatedBanner.vue';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';
import { RelatedBanner as RelatedBannerType } from '~/types/components/RelatedBanner/RelatedBanner';
import ProductSlider from '~/components/organisms/Product/ProductSlider/ProductSlider.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default defineComponent({
  name: 'RelatedProducts',
  components: {
    RelatedProduct,
    Link,
    ProductAnchorTitle,
    RelatedBanner,
    ProductSlider,
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    products: {
      type: Array as PropType<ProductVariant[]>,
      default: () => {
        return [];
      },
    },
    seeAllLinkText: {
      type: String,
      default: null,
    },
    seeAllLinkSlug: {
      type: String,
      default: null,
    },
    hasBanner: {
      type: Boolean,
      default: false,
    },
    banner: {
      type: Object as PropType<RelatedBannerType>,
      default: () => {
        return {};
      },
    },
    hasControlsOutsideContainer: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isProductZone: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
      default: 'text-EXPONDO-black',
    },
    linkClass: {
      type: String,
      default: 'text-EXPONDO-secondary',
    },
    prevArrowClass: {
      type: String,
      default: 'left-0',
    },
    nextArrowClass: {
      type: String,
      default: 'right-0',
    },
    size: {
      type: String as PropType<ProductCardSize>,
      default: PRODUCT_CARD_SIZE.RESPONSIVE,
      validator: (value: ProductCardSize) => {
        return Object.values(PRODUCT_CARD_SIZE).includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const { priceGuaranteeUrl } = useRouteBestPriceGuarantee();
    const { search: searchProductDeliveryDate, parcelDeliveryDate, freightDeliveryDate } = useProductDeliveryDate(true);

    onMounted(() => {
      searchProductDeliveryDate();
    });

    const sliderItems = computed(() => props.hasBanner ? [{ hasBanner: true }, ...props.products] : props.products);
    const productZoneClasses = 'bg-EXPONDO-white py-2.5 px-2 flex-1 lg:border-2 lg:border-EXPONDO-grey-10';
    const productSliderClasses = computed(() => props.isProductZone ? productZoneClasses : '');
    const showBannerButton = computed(() => props.hasBanner && props.banner.buttonLink && props.banner.buttonText);

    const relatedProductClicked = (sku: string) => {
      emit('product-clicked', sku);
    };

    return {
      LinkType,
      priceGuaranteeUrl,
      parcelDeliveryDate,
      freightDeliveryDate,
      sliderItems,
      productSliderClasses,
      showBannerButton,
      relatedProductClicked,
      PRODUCT_CARD_SIZE,
    };
  },
});

